(function ($) {
  /**
   * Typeahead: clear all the things
   */
  $(document).on('endeca.typeahead.clearall', function (event, searchFrom) {
    var $searchForm = $(searchFrom).length ? $(searchFrom) : $('.js-end-esearch-nav__form');
    var $searchField = $("input[name='search']", $searchForm);
    var $results = $('.esearch-nav__suggestions ul', $searchForm);

    // Reset everything
    $searchField.val('');
    $results.empty();
    $searchForm.removeClass('gnav-search--suggestions gnav-search--no-suggestions');
  });

  /**
   * Typeahead: init event handler
   */
  // $(document).on('endeca.typeahead.init', function(e) {
  // });

  /**
   * Typeahead: keyup event handler
   */
  $(document).on('endeca.typeahead.keyup', function () {
    var $searchForm = $('.js-end-esearch-nav__form');
    var $searchField = $("input[name='search']", $searchForm);

    $searchForm.each(function (index, el) {
      var $form = $(this);
      var $searchField = $("input[name='search']", $form);
      var $val = $searchField.val();

      if ($val.length < 1) {
        $(this).trigger('endeca.typeahead.clearall', $form);
      }
    });
  });

  /**
   * Typeahead: close event handler
   */
  $(document).on('endeca.typeahead.close', function () {
    $(this).trigger('endeca.typeahead.clearall');
  });

  /**
   * Typeahead: search complete event handler
   */
  $(document).on('endeca.typeahead.complete', function (e, hasResults) {
    var $searchForm = $('.js-end-esearch-nav__form');

    $searchForm.removeClass('esearch-nav--suggestions esearch-nav--no-suggestions');
    if (hasResults) {
      $searchForm.addClass('esearch-nav--suggestions');
    } else {
      $searchForm.addClass('esearch-nav--no-suggestions');
    }
  });

  Drupal.behaviors.esearchNavV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $typeaheads = $('.esearch-nav', context);

      $typeaheads.each(function (index, el) {
        var $typeahead = $(this);
        var $btn = $('.js-search-form__btn', $typeahead);

        $btn.once().on('click', function (event) {
          event.preventDefault();
          // console.log($('.js-end-esearch-nav__form', $typeahead));
          // console.log($('.js-end-esearch-nav__form', $typeahead).val());
          $('.js-end-esearch-nav__form', $typeahead).submit();
        });
      });
    },
    attached: false
  };
})(jQuery);
